import axios from "axios";
import Pusher from "pusher-js";
import * as React from "react";
import "./App.css";
import { UserInfo } from "./context";
import WelcomeRoute from "./routes/WelcomeRoute";
import { useNavigate } from "react-router-dom";
import AllRoute from "./routes/AllRoute";
import { message } from "antd";
import LoadingComponent from "./utils/LoadingComponent";

axios.defaults.baseURL = import.meta.env.VITE_API;
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "ptauetkn"
)}`;

const pusher = new Pusher(import.meta.env.VITE_API_PUSHER_KEY, {
  cluster: "ap1",
});

function MyApp() {
  const [cart, setCart] = React.useState(
    JSON.parse(localStorage.getItem("ptuaecart")) || []
  );
  const [wish, setWish] = React.useState(
    JSON.parse(localStorage.getItem("ptuaewish")) || []
  );
  const ptauetkn = localStorage.getItem("ptauetkn");
  const [isLoading, setIsLoading] = React.useState(true);
  const [userData, setUserData] = React.useState({
    data: [],
    ready: false,
  });
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= 650 ? true : false
  );
  // const [isLG, setIsLG] = React.useState(
  //   window.innerWidth >= 992 && window.innerWidth <= 1199 ? true : false
  // );

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const channel = pusher.subscribe("channels");

  const messagingNotif = (type, content) => {
    messageApi.open({
      type,
      content,
    });
  };

  const handleLogout = async () => {
    const response = await axios.post("auth/logout");
    if (response.data.message === "Successfully logged out") {
      messagingNotif("error", "Session Expire! Logged out automatically.");
      localStorage.removeItem("ptauetkn");
      navigate("/");
      navigate(0);
    }
  };

  const getTokenAndAccess = () => {
    axios
      .get("auth/me")
      .then(function (response) {
        const data = response.data;
        if (data.message === "success") {
          setUserData({
            data: data.result,
            ready: true,
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log("ERROR", error);
        if (error.message === "Request failed with status code 401") {
          handleLogout();
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      });
  };

  function detectWindowSize() {
    window.innerWidth <= 650 ? setIsMobile(true) : setIsMobile(false);
  }

  React.useEffect(() => {
    if (ptauetkn !== null) {
      getTokenAndAccess();
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    window.onresize = detectWindowSize;
    return () => {
      pusher.unsubscribe("channels");
    };

    // eslint-disable-next-line
  }, [ptauetkn, cart, wish, isMobile]);
  return (
    <UserInfo.Provider
      value={{
        channel,
        userData,
        setUserData,
        handleRerunUser: () => getTokenAndAccess(),
        handleLogoutTkn: () => handleLogout(),
        rerunNotif: (type, content) => messagingNotif(type, content),
        cart: cart,
        setCart: setCart,
        wish: wish,
        setWish: setWish,
        isMobile,
      }}
    >
      {isLoading && <LoadingComponent />}

      {!isLoading && (
        <>
          {userData.ready && <AllRoute module={userData.data.module} />}
          {!userData.ready && <WelcomeRoute />}
        </>
      )}

      {contextHolder}
    </UserInfo.Provider>
  );
}

export default function ToggleColorMode() {
  return <MyApp />;
}
