import React from "react";
import { Route, Routes } from "react-router-dom";
// import AdminAddProducts from "../components/admin/products";
import NotFoundComponent from "../utils/NotFoundComponent";
// import AdminDelivery from "../components/admin/delivery";
// import OrderToConfirm from "../components/admin/order/to-confirm";
// import ConfirmedOrder from "../components/admin/order/confirmed";
// import CompletedOrder from "../components/admin/order/complete";
// import BannerList from "../components/admin/banner";
import { Suspense } from "react";
import { lazy } from "react";

const AdminAddProducts = lazy(() => import("../components/admin/products"));
const AdminDelivery = lazy(() => import("../components/admin/delivery"));
const OrderToConfirm = lazy(() =>
  import("../components/admin/order/to-confirm")
);
const ConfirmedOrder = lazy(() =>
  import("../components/admin/order/confirmed")
);
const CompletedOrder = lazy(() => import("../components/admin/order/complete"));
const BannerList = lazy(() => import("../components/admin/banner"));

const AdminRoute = () => {
  return (
    <>
      <React.Fragment>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                height: "100vh",
                // border: "1px solid red",
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Loading...
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<AdminAddProducts />} />
            <Route path="/delivery" element={<AdminDelivery />} />
            <Route path="/order-to-confirm" element={<OrderToConfirm />} />
            <Route path="/confirmed-order" element={<ConfirmedOrder />} />
            <Route path="/completed-order" element={<CompletedOrder />} />
            <Route path="/banner" element={<BannerList />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </Suspense>
      </React.Fragment>
    </>
  );
};

export default AdminRoute;
