import {
  Fragment,
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Route, Routes } from "react-router-dom";
// import WelcomeDashboard from "../components/welcome/dashboard";
// import Login from "../components/welcome/login/admin";
import axios from "axios";
import { CustomerDisplayInfo, UserInfo } from "../context";
import NotFoundComponent from "../utils/NotFoundComponent";
// import CustomerLogin from "../components/welcome/login/customer";
// import WelcomeCart from "../components/welcome/cart";
// import WelcomeWish from "../components/welcome/wish";
// import WelcomeCheckout from "../components/welcome/checkout";
// import WelcomeShopByPet from "../components/welcome/shopbypet";
// import WelcomeShopByBrand from "../components/welcome/shopbybrands";
// import WelcomeSpecProduct from "../components/welcome/product-details";
import _ from "lodash";
// import PrivacyPolicy from "../components/welcome/privacy-policy";
// import TermsAndConditions from "../components/welcome/terms-condition";
// import Blog from "../components/welcome/blog";
// import AboutUs from "../components/welcome/about-us";
// import ContactUs from "../components/welcome/contact-us";

const WelcomeDashboard = lazy(() => import("../components/welcome/dashboard"));
const Login = lazy(() => import("../components/welcome/login/admin"));
const CustomerLogin = lazy(() =>
  import("../components/welcome/login/customer")
);
const WelcomeCart = lazy(() => import("../components/welcome/cart"));
const WelcomeWish = lazy(() => import("../components/welcome/wish"));
const WelcomeCheckout = lazy(() => import("../components/welcome/checkout"));
const WelcomeShopByPet = lazy(() => import("../components/welcome/shopbypet"));
const WelcomeShopByBrand = lazy(() =>
  import("../components/welcome/shopbybrands")
);
const WelcomeSpecProduct = lazy(() =>
  import("../components/welcome/product-details")
);
const PrivacyPolicy = lazy(() =>
  import("../components/welcome/privacy-policy")
);
const TermsAndConditions = lazy(() =>
  import("../components/welcome/terms-condition")
);
const Blog = lazy(() => import("../components/welcome/blog"));
const AboutUs = lazy(() => import("../components/welcome/about-us"));
const ContactUs = lazy(() => import("../components/welcome/contact-us"));
const Products = lazy(() => import("../components/welcome/products"));

const WelcomeRoute = () => {
  const [brandList, setBrandList] = useState({ data: [], ready: false });
  const [bestSellerList, setBestSellerList] = useState({
    data: [],
    ready: false,
  });
  const [bestOfferList, setBestOfferList] = useState({
    data: [],
    ready: false,
  });
  const [productList, setProductList] = useState({ data: [], ready: false });
  const { cart } = useContext(UserInfo);

  const getBrandList = useCallback(() => {
    axios
      .get("customer/brand-list")
      .then((response) => {
        const data = response.data;
        setBrandList({ data, ready: true });
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }, []);

  // const getBestSellerList = useCallback(() => {
  //   var params = { is_best_seller: 1 };
  //   axios
  //     .get("customer/product-list", { params })
  //     .then((response) => {
  //       const data = response.data;
  //       setBestSellerList({ data, ready: true });
  //     })
  //     .catch((error) => {
  //       console.log("ERROR: ", error);
  //     });
  // }, []);

  const calculateTotalQty = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total += parseInt(data[i].quantity) * 1;
    }
    return total;
  };

  const getProductList = useCallback(() => {
    var params = { is_best_seller: "all" };
    axios
      .get("customer/product-list", { params })
      .then((response) => {
        const data = response.data;
        const data2 = _.chain(data)
          .groupBy((g) => g.id)
          .map((value, key) => ({
            id: key,
            before_srp: parseFloat(value[0].before_srp),
            srp: parseFloat(value[0].srp),
            brand: value[0].brand,
            category: value[0].category,
            is_best_seller: parseInt(value[0].is_best_seller),
            off_description: value[0].off_description,
            is_srp_off: parseInt(value[0].is_srp_off),
            product: value[0].product,
            product_image: value[0].product_image,
            available: calculateTotalQty(value[0].productexpiry),
          }))
          .value();
        const res = _.filter(data2, function (item) {
          return item.is_best_seller === 1;
        });
        const res2 = _.filter(data2, function (item) {
          return item.is_srp_off === 1;
        });
        setProductList({ data: data2, ready: true });
        setBestSellerList({ data: res, ready: true });
        setBestOfferList({ data: res2, ready: true });
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }, []);

  useEffect(() => {
    getBrandList();
    // getBestSellerList();
    getProductList();
  }, [
    getBrandList,
    // getBestSellerList,
    getProductList,
  ]);

  // console.log("bestOfferList", bestOfferList);

  return (
    <Fragment>
      <CustomerDisplayInfo.Provider
        value={{
          brandList: brandList,
          bestSellerList: bestSellerList,
          productList: productList,
          bestOfferList: bestOfferList,
        }}
      >
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                height: "100vh",
                // border: "1px solid red",
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Loading...
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<WelcomeDashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/customer-login" element={<CustomerLogin />} />
            <Route path="/cart" element={<WelcomeCart />} />
            <Route path="/wish-list" element={<WelcomeWish />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/products" element={<Products />} />

            <Route
              path="/terms-and-condition"
              element={<TermsAndConditions />}
            />

            <Route path="/pet/:pet" element={<WelcomeShopByPet />} />
            <Route
              path="/brand/:brand/:brand_id"
              element={<WelcomeShopByBrand />}
            />
            <Route
              path="/product/:product/:product_id"
              element={<WelcomeSpecProduct />}
            />

            {cart.length > 0 && (
              <Route path="/checkout" element={<WelcomeCheckout />} />
            )}

            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </Suspense>
      </CustomerDisplayInfo.Provider>
    </Fragment>
  );
};

export default WelcomeRoute;
