import Lottie from "lottie-react";
import loadingAnimation from "./../utils/animation/Loading.json";

const LoadingComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Lottie
        animationData={loadingAnimation}
        loop={true}
        style={{ width: window.innerWidth > 500 ? 150 : 100 }}
      />
    </div>
  );
};

export default LoadingComponent;
