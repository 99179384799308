import Lottie from "lottie-react";
import notFoundAnimation from "./../utils/animation/NotFound.json";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFoundComponent = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 - Pet Trends</title>
        <link rel="canonical" href={`http://pettrends.online`} />
      </Helmet>

      <Lottie
        animationData={notFoundAnimation}
        loop={true}
        style={{ width: window.innerWidth > 500 ? 400 : 250 }}
      />
      <div style={{ marginBottom: 20 }}>
        <Typography style={{ textAlign: "center", fontSize: 20 }}>
          Sorry, the page you visited does not exist.
        </Typography>
      </div>
      <div>
        <Button size="large" type="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default NotFoundComponent;
